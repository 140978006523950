import React from 'react';

import styles from 'styles/components/home/Home.module.scss';

const AboutUs = ({ user, indexPage = false }) => (
  <div className={styles.aboutUs}>
    {user === 'advertiser' ? (
      <div className={styles.text}>
        <span>
          Got more questions? Check out our{' '}
          <a
            href="https://gigsocial.crisp.help/en-us/category/advertisers-7fa8h5/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Advertiser FAQ
          </a>
        </span>
      </div>
    ) : (
      <div className={styles.text}>
        <span>
          Still got questions? Check out our{' '}
          <a
            href="https://gigsocial.crisp.help/en-us/category/influencers-14y6c7a/"
            target="blank"
            rel="noreferrer noopener"
          >
            Influencer FAQ
          </a>
        </span>
      </div>
    )}

    {!indexPage && (
      <>
        <h1>About us</h1>

        <p>
          Located in Los Angeles and Las Vegas, GigSocial was built by influencers for influencers.
          We know the struggles of managing paid social media posts and have built GigSocial to make
          our lives easier. We’ve served over 1,000 influencers and helped produce over 100 million
          paid impressions generating millions per year for our influencers. Some of our top
          influencers have made close to $1m+ from our site alone. We payout influencers in over 120
          countries.
        </p>
      </>
    )}

    <ul className={styles.bottomIcons} style={indexPage ? { paddingTop: 0 } : {}}>
      <li>
        <span>Influencers</span>
        <span className={styles.number}>4,000+</span>
      </li>

      <li>
        <span>Paid impressions</span>
        <span className={styles.number}>1b+</span>
      </li>

      <li>
        <span>Influencer revenue</span>
        <span className={styles.number}>$5m+</span>
      </li>

      <li>
        <span>Countries</span>
        <span className={styles.number}>120+</span>
      </li>
    </ul>
  </div>
);

export default AboutUs;
