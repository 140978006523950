import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/SmallCard.module.scss';

const SmallCard = ({ bottomText, src, srcSet, alt, isLast }) => (
  <div className={styles.cardContainer} style={isLast ? { marginRight: 0 } : {}}>
    <img src={src} srcSet={srcSet} alt={alt} width="100%" height="300px" />
    <div className={styles.bottom}>
      <span>{bottomText}</span>
    </div>
  </div>
);

SmallCard.propTypes = {
  bottomText: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  isLast: PropTypes.bool,
};

SmallCard.defaultProps = {
  isLast: false,
  srcSet: '',
};

export default SmallCard;
