import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from 'styles/components/home/Home.module.scss';

const Animation = ({ src, styleAnimation }) => (
  <div className={clsx(styles.animation, { [styleAnimation]: styleAnimation })}>
    <iframe
      src={src}
      width="100%"
      height="560"
      style={{ height: '100%', border: 0, display: 'block', margin: '0 auto' }}
    />
  </div>
);

Animation.propTypes = {
  src: PropTypes.string.isRequired,
  styleAnimation: PropTypes.string,
};

Animation.defaultProps = {
  styleAnimation: '',
};

export default Animation;
