import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import Animation from 'components/home/Animation';
import styles from 'styles/components/CardWithImage.module.scss';

const CardWithImage = ({
  imageSrc,
  imageSrcSet,
  title,
  description,
  alt,
  reverse,
  animationSrc,
  backgroundColor,
  unorderedList,
}) => (
  <Grid container className={styles.container} style={backgroundColor}>
    <div className={styles.content}>
      <Grid item xs={12} md={6} className={clsx(styles.text, { [styles.second]: reverse })}>
        <h1>{title}</h1>
        {description && <p>{description}</p>}

        {unorderedList.length > 0 && (
          <ul>
            {unorderedList.map(item => (
              <li key={item.id}>
                <h4>{item.title}</h4>
                <span>{item.description}</span>
              </li>
            ))}
          </ul>
        )}
      </Grid>

      <Grid item xs={12} md={6} className={clsx(styles.imgContainer, { [styles.first]: reverse })}>
        {imageSrc ? (
          <img src={imageSrc} srcSet={imageSrcSet} width={'100%'} alt={alt} />
        ) : (
          <Animation src={animationSrc} />
        )}
      </Grid>
    </div>
  </Grid>
);

CardWithImage.propTypes = {
  imageSrc: PropTypes.string,
  imageSrcSet: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  animationSrc: PropTypes.string,
  alt: PropTypes.string,
  reverse: PropTypes.bool,
  backgroundColor: PropTypes.object,
  unorderedList: PropTypes.array,
};

CardWithImage.defaultProps = {
  imageSrc: '',
  reverse: false,
  imageSrcSet: '',
  alt: '',
  animationSrc: '',
  backgroundColor: { backgroundColor: '#fff' },
  description: '',
  unorderedList: [],
};

export default CardWithImage;
