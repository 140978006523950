import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

const useAuthRedirect = (path = '/') => {
  const router = useRouter();
  const isLoggedIn = useSelector(state => state.auth.loggedIn);

  useEffect(() => {
    router.prefetch(path);
  }, [path, router]);

  useEffect(() => {
    if (isLoggedIn) {
      router.push(path);
    }
  }, [isLoggedIn, path, router]);
};

export default useAuthRedirect;
