import React from 'react';
import Link from 'next/link';
import { Button } from '@material-ui/core';

import CheckIcon from 'public/icons/check-circle.svg';
import ScaleIcon from 'public/icons/scale.svg';
import MedalDocument from 'public/icons/medal-document.svg';
import PaperAirplaneIcon from 'public/icons/paper-airplane.svg';
import DashboardIcon from 'public/icons/dashboard.svg';
import ProtectionIcon from 'public/icons/protection.svg';
import ReportsIcon from 'public/icons/reports.svg';
import WalletIcon from 'public/icons/wallet.svg';
import StarUserIcon from 'public/icons/star-user.svg';
import HandWithCirclesIcon from 'public/icons/hand-with-circles.svg';
import TargetIcon from 'public/icons/target.svg';
import AcceptIcon from 'public/icons/accept.svg';
import styles from 'styles/components/home/NilCard.module.scss';

const NilCard = ({ title = '' }) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <span style={title === 'Schools' ? { color: '#12dfc7' } : { color: '#ba94ef' }}>
        BENEFITS FOR
      </span>

      <h1>{title}</h1>
    </div>
    <div className={styles.body}>
      {title === 'Schools' ? (
        <ul>
          <li>
            <CheckIcon />
            <span>Compliance of NCAA rules & regulations of NIL deals</span>
          </li>

          <li>
            <ScaleIcon />
            <span>Adherence to all current state laws</span>
          </li>

          <li>
            <MedalDocument />
            <span>Proof of service for all athlete NIL deals</span>
          </li>

          <li>
            <PaperAirplaneIcon />
            <span>Automated reporting of NIL deals for disclosure</span>
          </li>

          <li>
            <DashboardIcon />
            <span>Dashboard for NIL school official</span>
          </li>

          <li>
            <ProtectionIcon />
            <span>Prevention of prohibited NIL deals based on state and school requirements</span>
          </li>

          <li>
            <ReportsIcon />
            <span>Auditing and reports</span>
          </li>

          <Link href="/sign-up/athlete" passHref>
            <Button variant="contained" className={styles.greenBtn}>
              Sign up now
            </Button>
          </Link>
        </ul>
      ) : (
        <ul>
          <li>
            <WalletIcon />
            <span>Tax tracking and financial service integration</span>
          </li>

          <li>
            <StarUserIcon />
            <span>Brand & image education and pricing suggestions</span>
          </li>

          <li>
            <HandWithCirclesIcon />
            <span>End of year automated tax filing and accounting</span>
          </li>

          <li>
            <TargetIcon />
            <span>
              Automating the process of buying and selling saving students and schools thousands of
              hrs per year
            </span>
          </li>

          <li>
            <AcceptIcon />
            <span>
              Direct buying facilitated from collectives, schools, local businesses, national
              brands, and even fans
            </span>
          </li>

          <Link href="/sign-up/athlete" passHref>
            <Button variant="contained" className={styles.mainBtn}>
              Sign up now
            </Button>
          </Link>
        </ul>
      )}
    </div>
  </div>
);

export default NilCard;
