import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Hidden } from '@material-ui/core';
import DocumentHead from 'components/DocumentHead';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import useAuthRedirect from 'hooks/useAuthRedirect';
import useScrollToTop from 'hooks/useScrollToTop';
import DashedArrowIcon from 'public/icons/dashed-arrow.svg';
import InfluencerSection from 'components/home/InfluencerSection';
import AboutUs from 'components/home/AboutUs';
import AdvertiserSection from 'components/home/AdvertiserSection';
import HomeSection from 'components/home/HomeSection';
import FAQ from 'components/home/FAQ';
import styles from 'styles/components/home/Home.module.scss';

const getDefaultTab = query => {
  if ('i' in query || query?.['utm_term'] === 'influencer') return 'influencer';
  if ('a' in query || query?.['utm_term'] === 'buyer') return 'advertiser';
  // if ('n' in query || query?.['utm_term'] === 'nil') return 'nil';
  return 'influencer';
};

const iconStyle = { width: '100%', textAlign: 'center', marginBottom: 50 };

const Home = () => {
  const [user, setUser] = useState('influencer');
  const { query } = useRouter();

  useScrollToTop();
  useAuthRedirect('/dashboard');

  useEffect(() => {
    const tab = getDefaultTab(query);

    if (tab !== 'nil') setUser(tab);
  }, [query]);

  return (
    <div className={styles.homeContainer}>
      <DocumentHead>
        <title>Home | GigSocial</title>
      </DocumentHead>

      <div className={styles.mask}>
        <div className={styles.background}>
          <div className={styles.rightSection}>
            <div className={styles.angle} />

            <Hidden smUp>
              <>
                <h2>
                  {/* The marketplace for buying influencer & athlete cross platform brand deals
                  directly */}
                  Buy creator posts, shoutouts, & UGC
                </h2>

                <div>
                  <h3 style={{ margin: '30px auto 0', maxWidth: 380 }}>
                    {/* Buy posts, license name, image and likeness, directly from top creators and
                    athletes, or post your jobs and get matched based on audiences and budget. */}
                    Find creators that match your campaign and budget. See their digital rate card.
                    Buy directly now.
                  </h3>
                </div>
              </>
            </Hidden>
          </div>
        </div>
      </div>

      <Grid container className={styles.coverImage}>
        <Grid item xs={12} sm={5} lg={4} className={styles.imgContainer}>
          <img
            src="/images/home/smaller-homepage.png"
            srcSet="images/home/smaller-best-matches@2x-min.png"
            alt="Best matches"
            width="100%"
          />

          <img
            src="/images/home/new-homepage.png"
            srcSet="images/home/new-homepage@2x.png"
            alt="Influencer's page"
            width="100%"
          />
        </Grid>

        <Grid item xs={12} sm={7} lg={8} className={styles.rightSectionContainer}>
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <Hidden xsDown>
              <div className={styles.rightSection}>
                <h2>
                  {/* The marketplace for buying influencer & athlete cross platform brand deals
                  directly */}
                  Buy creator posts, shoutouts, & UGC
                </h2>

                <div>
                  <h3 style={{ margin: '30px auto 0 auto', maxWidth: 580 }}>
                    {/* Buy posts, license name, image and likeness, directly from top creators and
                    athletes, or post your jobs and get matched based on audiences and budget. */}
                    Find creators that match your campaign and budget. See their digital rate card.
                    Buy directly now.
                  </h3>
                </div>
              </div>
            </Hidden>

            <div>
              <Link href="/sign-up/advertiser" passHref>
                <Button variant="contained" color="primary" className={styles.greenBtn}>
                  Start Buying
                </Button>
              </Link>

              <Link href="/sign-up/influencer" passHref>
                <Button variant="outlined" color="primary" className={styles.outlinedBtn}>
                  Start Selling
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className={styles.content}>
        <Hidden mdUp>
          <div className={styles.whiteBackground} />
          <div className={styles.shadow} />
        </Hidden>

        <HomeSection user={user} setUser={setUser} />

        <div className={styles.middleButton}>
          {user === 'influencer' && (
            <Link href="/sign-up/influencer" passHref>
              <Button color="primary" variant="contained">
                Sign up now
              </Button>
            </Link>
          )}

          {user === 'advertiser' && (
            <Link href="/sign-up/advertiser" passHref>
              <Button variant="contained" className={styles.mainGreenBtn}>
                Sign up now
              </Button>
            </Link>
          )}
        </div>

        {user === 'influencer' && <InfluencerSection />}

        {user === 'advertiser' && <AdvertiserSection />}

        {user === 'advertiser' && (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <DashedArrowIcon style={iconStyle} />

            <div style={{ paddingBottom: 100 }}>
              <Link href="/sign-up/advertiser" passHref>
                <Button variant="contained" color="primary">
                  List your gigs for free
                </Button>
              </Link>
            </div>
          </div>
        )}

        <FAQ user={user} />

        <AboutUs user={user} indexPage={true} />
      </div>
    </div>
  );
};

export default Home;
