import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InstagramIcon from 'public/icons/network/outline/instagram.svg';
import TikTokIcon from 'public/icons/network/outline/tiktok.svg';
import FacebookIcon from 'public/icons/network/outline/facebook.svg';
import YouTubeIcon from 'public/icons/network/outline/youtube.svg';
import TwitterIcon from 'public/icons/network/outline/twitter.svg';
import SnapchatIcon from 'public/icons/network/outline/snapchat.svg';
import PodcastIcon from 'public/icons/network/outline/podcast.svg';
import NilCard from './NilCard';
import ImageLikenessIcon from 'public/icons/network/outline/image_likeness.svg';
import AnimateListItem from 'components/animations/AnimateListItem';
import AnimateList from 'components/animations/AnimateList';
import SmallCard from 'components/SmallCard';
import styles from 'styles/components/home/Home.module.scss';

const HomeSection = ({ user, setUser }) => (
  <>
    <div className={styles.tabs}>
      <div
        className={clsx(styles.tab, { [styles.active]: user === 'advertiser' })}
        onClick={() => setUser('advertiser')}
      >
        <span style={user === 'advertiser' ? { opacity: 1 } : { opacity: 0.5 }}>Brands</span>
      </div>

      <div
        className={clsx(styles.tab, { [styles.active]: user === 'influencer' })}
        onClick={() => setUser('influencer')}
      >
        <span style={user === 'influencer' ? { opacity: 1 } : { opacity: 0.5 }}>Creators</span>
      </div>
    </div>

    <AnimateList key={user}>
      <div
        className={styles.homeSection}
        style={user === 'nil' ? { width: '100%', padding: 0, backgroundColor: '#fff' } : {}}
      >
        {user === 'influencer' && (
          <AnimateListItem>
            <div className={styles.section}>
              <h1>Your new EVERYTHING app for creator monetization</h1>

              <iframe
                src="https://www.youtube.com/embed/_KJpzcsQduM"
                width="100%"
                height="550"
                allowFullScreen
              />

              <p className={styles.subtitle}>Launch your personalized store in minutes!</p>
            </div>
          </AnimateListItem>
        )}

        {user === 'advertiser' && (
          <AnimateListItem>
            <div className={styles.section}>
              <h1>Buy from all the networks in one place</h1>
              <p>100+ inventory spaces for influencers to promote your brand</p>

              <ul>
                <li>
                  <InstagramIcon width={50} height={50} />
                  <h6>Instagram</h6>
                </li>

                <li>
                  <TikTokIcon width={50} height={50} />
                  <h6>TikTok</h6>
                </li>

                <li>
                  <FacebookIcon width={50} height={50} />
                  <h6>Facebook</h6>
                </li>

                <li>
                  <YouTubeIcon width={50} height={50} />
                  <h6>YouTube</h6>
                </li>

                <li>
                  <TwitterIcon width={50} height={50} />
                  <h6>Twitter</h6>
                </li>

                <li>
                  <SnapchatIcon width={50} height={50} />
                  <h6>Snapchat</h6>
                </li>

                <li>
                  <PodcastIcon width={50} height={50} />
                  <h6>Podcasts</h6>
                </li>

                <li>
                  <ImageLikenessIcon width={50} height={50} />
                  <h6>Image & Likeness</h6>
                </li>
              </ul>
            </div>
          </AnimateListItem>
        )}

        {user === 'nil' && (
          <AnimateListItem>
            <div className={styles.nilSection}>
              <div className={styles.shadow} />

              <div className={styles.imgContainer}>
                <img
                  src="images/home/nil-background.jpg"
                  srcSet="images/home/smaller-nil-background@2x-min.jpeg"
                  alt="NIL background"
                />

                <img
                  className={styles.curveImg}
                  src="images/white-bottom-curve.png"
                  alt="Bottom Curve"
                />
              </div>

              <div className={styles.nilContent}>
                <h1>Name, Image & Likeness</h1>
                <p>
                  GigSocial has created the first software solution dedicated to automating the
                  buying of name image and likeness deals from athletes of all levels.
                </p>
              </div>

              <div className={styles.cardContainer}>
                <NilCard title="Schools" />

                <NilCard title="Students" />
              </div>

              <div className={styles.smallCardContainer}>
                <SmallCard
                  bottomText="Book Athlete Appearances Directly"
                  src="images/home/athlete-meetups.jpg"
                  alt="Athlete meetups"
                />

                <SmallCard
                  bottomText="License Signatures"
                  src="images/home/license-signature.jpg"
                  srcSet="images/home/license-signature@2x.jpg"
                  alt="License signatures"
                />

                <SmallCard
                  bottomText="License Images & Videos"
                  src="images/home/license-images-videos.jpg"
                  srcSet="images/home/license-images-videos@2x.jpg"
                  alt="License images and videos"
                  isLast
                />
              </div>

              {/* <Partners /> */}
            </div>
          </AnimateListItem>
        )}
      </div>
    </AnimateList>
  </>
);

HomeSection.propTypes = {
  user: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default HomeSection;
