import React from 'react';

import CardWithImage from 'components/CardWithImage';
import styles from 'styles/components/home/Home.module.scss';

const AdvertiserSection = () => (
  <div className={styles.sectionContainer}>
    <CardWithImage
      title="Complex buying made easy"
      description="Buy multiple feed posts, story posts, live posts from Instagram, Tik Tok, and other networks from influencers in 30 seconds."
      alt="Screenshot"
      animationSrc="complex-buying.html"
    />

    <CardWithImage
      title="Manage activity and approvals"
      description="Manage dozens of active influencer gigs and offers sent and received right from your dashboard. Receive notifications of every post and approve before any money is released."
      animationSrc="dashboard-animation.html"
      backgroundColor={{ backgroundColor: '#eef1f7' }}
      reverse
    />

    <CardWithImage
      title="Intelligent Influencer Matching"
      imageSrc="images/home/best-matches.png"
      imageSrcSet="images/home/best-matches@2x.png"
      description="Be matched with influencers automatically based on budget, target audience, audience interests, influencer’s previous product success, influencer ratings & reliability, and much more."
      alt="Screenshot"
    />
  </div>
);

export default AdvertiserSection;
