import React from 'react';

import CardWithImage from 'components/CardWithImage';
import styles from 'styles/components/home/Home.module.scss';

const InfluencerSection = () => (
  <div className={styles.sectionContainer} style={{ marginBottom: '-10px' }}>
    <CardWithImage
      title="Selling on GigSocial"
      unorderedList={[
        {
          id: 1,
          title: 'Easily sell',
          description:
            'Promo on your socials | Your wishlist | 1 on 1 videos | Custom Videos | Event Appearances and more…',
        },
        {
          id: 2,
          title: 'Easily Manage',
          description:
            'Accept or deny orders that come in. All orders are arranged by priority in one easy-to-use dashboard.',
        },
        {
          id: 3,
          title: 'Easily get paid',
          description:
            'We handle all the payments for you, so you don’t have to, and pay you once the order has been completed.',
        },
      ]}
      imageSrc="/images/home/heartcore.jpg"
      imageSrcSet="/images/home/heartcore@2x.jpg"
      alt="Girl in the gym"
    />

    <CardWithImage
      title="Buying on GigSocial"
      unorderedList={[
        {
          id: 1,
          title: 'Discover',
          description:
            'Browse through thousands of other creators to find the best match for what you are looking for.',
        },
        {
          id: 2,
          title: 'Manage',
          description:
            'We’ll send you notifications when the order has been accepted, posted, and completed.',
        },
        {
          id: 3,
          title: 'Approve',
          description:
            'Post not completed as requested? The order isn’t closed and paid out until you are happy.',
        },
      ]}
      imageSrc="/images/home/man-with-sunglasses.jpg"
      imageSrcSet="/images/home/man-with-sunglasses@2x.jpg"
      alt="Man with sunglasses"
      backgroundColor={{ backgroundColor: '#eef1f7' }}
      reverse
    />
  </div>
);

export default InfluencerSection;
