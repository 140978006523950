import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

import AddIcon from 'public/icons/add-main.svg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 70,
    padding: '0 15px 0 15px',
    fontSize: 16,
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',

    '&$expanded': {
      borderBottom: 'none',
    },
  },

  content: {
    margin: 0,
  },

  body1: {
    fontSize: 16,
    paddingBottom: 15,
  },

  expanded: {
    margin: '0 !important',
    minHeight: '70px !important',
    alignItems: 'center',
  },

  expandIcon: {
    padding: 0,
    margin: 0,
    transition: 'all 0.3s ease-in-out',
    transform: 'rotate(90deg)',

    '&$expanded': {
      transform: 'rotate(0deg)',
      transition: 'all 0.3s ease-in-out',
    },
  },

  paper: {
    boxShadow: 'none',
    margin: '0 15px !important',

    '&::before': {
      display: 'none',
    },

    [theme.breakpoints.up('lg')]: {
      margin: '0 !important',
    },
  },
}));

const faqs = {
  influencerFaqs: [
    {
      question: 'What fees does GigSocial charge?',
      answer:
        'Only 5% on orders that come from your link page. Orders that come through our matching system vary based on order size, but average out to around 10%.',
    },
    {
      question: 'What countries do you payout to?',
      answer: 'Almost every country in the world.',
    },
    {
      question: 'How do I know how much to charge for promo?',
      answer:
        'We recommend pricing for you based on your following, but feel free to change the pricing at anytime.',
    },
    {
      question: 'How can I leverage GigSocial to make $10,000+ a month?',
      answer:
        'Promote your GigSocial page on your socials and bring in some orders of your own. Accept new orders quickly and get 5 star reviews. This will give confidence to prospective buyers. As you continue to get new orders, you will move up the rankings in GigSocial’s matching system and more orders will start to come in on top of the ones coming from your socials.',
    },
    {
      question: 'Can I list multiple accounts?',
      answer:
        'Yes. After creating an account, in your dashboard menu up top, you can easily add additional accounts.',
    },
  ],

  advertiserFaqs: [
    {
      question: 'What fees does GigSocial charge?',
      answer: 'We charge a small transaction fee to each order which comes out to about 5%.',
    },
    {
      question: 'What forms of payment do you accept?',
      answer:
        'Right now you can pay with credit card or bank transfer. To do a bank transfer, you can message support at anytime.',
    },
    {
      question: 'How can I find best creators that will deliver highest ROI?',
      answer:
        'Upon signing up, you can browse through our Discover system where our highest performing creators are listed up top. You can also create a Public Offer, which will connect you with the best creators for your budget.',
    },
    {
      question: 'What happens if the Creator doesn’t deliver on the agreed terms?',
      answer:
        'Funds are never released to the creator until you approve the delivery. If there is an issue, you can work it out with the creator or contact support.',
    },
    {
      question:
        'I’m trying to get my brand in front of the creators. Do you have any way to do this?',
      answer:
        'We offer a partnership program for brands that want to advertise their product or service to the creators on our platform. Contact support to get more info.',
    },
  ],
};

const style = { paddingRight: 15, margin: '10px 0' };

const FAQ = ({ user }) => {
  const classes = useStyles();
  const isInfluencer = user === 'influencer';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div
      style={{
        padding: isMobile ? '20px 0 50px 0' : '20px calc((100% - 800px) / 2) 100px',
        backgroundColor: '#eef1f7',
        margin: '0 auto -10px',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: 15 }}>FAQ</h1>
      {isInfluencer
        ? faqs.influencerFaqs.map((faq, index) => (
            <Accordion className={classes.paper} key={index}>
              <AccordionSummary
                classes={{
                  root: classes.root,
                  content: classes.content,
                  expanded: classes.expanded,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<AddIcon width={16} height={16} />}
              >
                <h3 style={style}>{faq.question}</h3>
              </AccordionSummary>

              <AccordionDetails className={classes.root}>
                <Typography className={classes.body1}>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        : faqs.advertiserFaqs.map((faq, index) => (
            <Accordion className={classes.paper} key={index}>
              <AccordionSummary
                classes={{
                  root: classes.root,
                  content: classes.content,
                  expanded: classes.expanded,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<AddIcon width={16} height={16} />}
              >
                <h3 style={style}>{faq.question}</h3>
              </AccordionSummary>

              <AccordionDetails className={classes.root}>
                <Typography className={classes.body1}>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
    </div>
  );
};

export default FAQ;
